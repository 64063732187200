import type {
  SubmittedDocument,
  SubmittedDocuments,
} from "types/SubmittedDocument";
import { DocumentGradedState } from "~/types/enums/DocumentGradedState.enum";
import {
  DocumentSubmissionType,
  DocumentSubmissionTypes,
} from "~/types/enums/DocumentSubmissionType.enum";
import { Assignment, type Assignments } from "../assignments/assignment.model";
import { BaseModel } from "../base.model";
import { Classroom, type Classrooms } from "../classrooms/classroom.model";
import { Student, type Students } from "../students/student.model";
import { Demo } from "./demo.model";
// @ts-ignore

import { DBFactory } from "classes/database/db_factory";
import {
  CollectionReference,
  type DocumentData,
  DocumentReference,
  Timestamp,
  WriteBatch,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
  writeBatch,
} from "firebase/firestore";
import { ref as FileRef, getDownloadURL } from "firebase/storage";
import { Paragraph } from "types/Paragraph";
import { Revision } from "types/Revision";
import { useFirebaseStorage } from "vuefire";
import {
  StudentCriteriaStatistic,
  StudentCriteriaStatistics,
} from "../assignment-statistics/student-criteria-statistics.model";
import { AssignmentsService } from "../assignments/assignments.service";
import {
  ClassroomEmail,
  ClassroomEmails,
} from "../classroom-emails/classroom-email.model";
import { ClassroomsService } from "../classrooms/classrooms.service";
import { CriteriaGroups } from "../criteria/criteria-group.model";
import { FileResource } from "../education-resources/education-resource.model";
import {
  StudentLinkedAccount,
  StudentLinkedAccounts,
} from "../studentLinkedAccounts/student-linked-account.model";

export class DemosService {
  static async listDemos() {
    const db = DBFactory.createDatabase();
    const data = await db.list({
      collection: "demos",
      path: "demos",
    });

    return data.map((map) => {
      return Demo.fromMap(map);
    });
  }

  static generateWeightedRandom() {
    const random = Math.random();

    if (random < 0.05) {
      // Generate a number between 0 and 4 (inclusive of 0 and exclusive of 5)
      return Math.floor(Math.random() * 5);
    } else if (random < 0.1) {
      // Generate a number between 5 and 9 (inclusive of 5 and exclusive of 9)
      return Math.floor(5 + Math.random() * 4);
    } else {
      // Generate a number between 9 and 10 (inclusive of 9 and exclusive of 10)
      // Adjusted to generate a float between 9 and 10, since the upper limit is non-inclusive
      return 9 + Math.random();
    }
  }

  static async getDemo(id: string) {
    const db = DBFactory.createDatabase();
    const data = await db.get({
      collection: "demos",
      path: `demos/${id}`,
    });

    return Demo.fromMap(data);
  }

  static async createStudentCriteriaData(
    uid: string,
    classroomId: string,
    classroomName: string,
    assignments: Assignments,
    students: Students,
    criteriaGroups: CriteriaGroups
  ) {
    const db = useFirestore();

    // First we're going to delete all the data for the classroom in case I'm repopulating.
    const classroomStatisticsRef = collection(
      db,
      `/assignmentStatistics/default/criteria`
    );
    const classroomStatisticsQuery = query(
      classroomStatisticsRef,
      where("classroomId", "==", classroomId)
    );
    const classroomStatisticsSnapshot = await getDocs(classroomStatisticsQuery);

    let deleteBatch = writeBatch(db);
    let index = 0;
    for (const doc of classroomStatisticsSnapshot.docs) {
      deleteBatch.delete(doc.ref);
      index++;

      if (index >= 500) {
        await deleteBatch.commit();
        deleteBatch = writeBatch(db);
        index = 0;
      }
    }

    await deleteBatch.commit();

    for (const student of students) {
      const statistics = [] as StudentCriteriaStatistics;

      for (const group of criteriaGroups) {
        const studentCriteriaStatistic = new StudentCriteriaStatistic({
          checklistCriteriaId: group.checklistCriteriaIds[0],
          teacherId: uid,
          studentId: student.id!,
          classroomId: classroomId,
          mapAssignmentIdScore: {},
        });

        for (const assignment of assignments) {
          studentCriteriaStatistic.mapAssignmentIdScore[assignment.id!] = {
            maxPoints: 10,
            earnedPoints: DemosService.generateWeightedRandom(),
          };
        }

        statistics.push(studentCriteriaStatistic);
      }

      const db = DBFactory.createDatabase();
      await db.batchUpdate(statistics);
    }
  }

  static async createKWODocs({
    userId,
    classroomId,
    classroomName,
    assignmentId,
    assignmentName,
    classroomStudents,
  }: {
    userId: string;
    classroomId: string;
    classroomName: string;
    assignmentId: string;
    assignmentName: string;
    classroomStudents: Students;
  }) {
    const db = useFirestore();
    const batch = writeBatch(db);
    const documentIdToCopy = "kYaMeNVxNK4QNGCaw9LJ";
    const documentRef = doc(db, `documents/${documentIdToCopy}`);
    const documentSnapshot = await getDoc(documentRef);
    const submittedDocument = {
      ...documentSnapshot.data(),
      id: documentIdToCopy,
    } as SubmittedDocument;

    submittedDocument.classroomId = classroomId;
    submittedDocument.classroomName = classroomName;
    submittedDocument.assignmentId = assignmentId;
    submittedDocument.assignmentName = assignmentName;
    submittedDocument.state = DocumentState.submitted;
    submittedDocument.shouldCopyFullDocument = true;

    for (const student of classroomStudents) {
      await this.copyDocumentToUser({
        submittedDocument,
        userId,
        studentId: student.id!,
        studentName: student.name,
        copyFullDocument: true,
        initialBatch: batch,
        submissionType: DocumentSubmissionType.keyWordOutline,
      });
    }

    await batch.commit();
  }

  static async createAssignmentDocs(
    userId: string,
    classroomId: string,
    classroomName: string,
    assignmentId: string,
    assignmentName: string,
    classroomStudents: Students
  ) {
    const db = useFirestore();
    const batch = writeBatch(db);

    const documentIdToCopy = "ESUi41TJ68DsIle6nyHY";

    const documentRef = doc(db, `documents/${documentIdToCopy}`);
    const documentSnapshot = await getDoc(documentRef);
    const submittedDocument = {
      ...documentSnapshot.data(),
      id: documentIdToCopy,
    } as SubmittedDocument;

    submittedDocument.classroomId = classroomId;
    submittedDocument.classroomName = classroomName;
    submittedDocument.assignmentId = assignmentId;
    submittedDocument.assignmentName = assignmentName;

    for (const student of classroomStudents) {
      for (const submissionType of DocumentSubmissionTypes) {
        await this.copyDocumentToUser({
          submittedDocument,
          userId,
          studentId: student.id!,
          studentName: student.name,
          copyFullDocument: false,
          initialBatch: batch,
          submissionType: submissionType.value,
        });
      }
    }

    await batch.commit();
  }

  static async createDemo(
    classroomEmails: ClassroomEmails,
    classrooms: Classrooms,
    assignments: Assignments,
    students: Students,
    studentLinkedAccounts: StudentLinkedAccounts,
    submissions: SubmittedDocuments,
    studentCriteriaTransactions: StudentCriteriaStatistics
  ) {
    let modelsToSave = [] as BaseModel[];

    const demo = new Demo({
      name: "New Demo",
    });

    await demo.save();

    if (!demo.id) {
      return;
    }

    const db = DBFactory.createDatabase();

    const formattedClassrooEmails = await DemosService.formatClassroomEmails(
      `/demos/${demo.id}`,
      classroomEmails
    );

    const emailsInfo = formattedClassrooEmails
      .filter((email) => email.info != undefined)
      .map((email) => {
        return email.info!;
      });

    modelsToSave = [...modelsToSave, ...formattedClassrooEmails, ...emailsInfo];

    const formatClassrooms = await DemosService.formatClassrooms(
      `/demos/${demo.id}/`,
      classrooms
    );

    await DemosService.copyClassroomsResources({
      demoId: demo.id,
      classrooms,
    });

    modelsToSave = [...modelsToSave, ...formatClassrooms];

    const formatAssignments = DemosService.formatAssignments(
      `/demos/${demo.id}`,
      assignments
    );

    modelsToSave = [...modelsToSave, ...formatAssignments];
    const formatStudents = DemosService.formatStudents(
      `/demos/${demo.id}`,
      students
    );

    modelsToSave = [...modelsToSave, ...formatStudents];

    const formatStudentLinkedAccounts =
      DemosService.formatStudentLinkedAccounts(
        `/demos/${demo.id}`,
        studentLinkedAccounts
      );

    modelsToSave = [...modelsToSave, ...formatStudentLinkedAccounts];

    await db.batchUpdate(modelsToSave);

    await DemosService.saveStudentCriteriaTransactions(
      `/demos/${demo.id}`,
      studentCriteriaTransactions
    );

    // Ok now whwat I need to do is fetc hthe full document which menas getting the revision, paragraphs, checklists, evaluative paragraphs etc
    // and then I need to create a batch so let's do the batch and separate the functions into a lot of writes and stuff
    // Ithink what I'll do is treat each document as a separte write though otherwise it'll probbly be tool large or just crat a nw batch
    const gradedSubmissions = submissions.filter(
      (s) => s.state == DocumentState.graded
    );

    const firestoreDB = useFirestore();
    let batch = writeBatch(firestoreDB);

    let index = 0;

    for (const submission of gradedSubmissions) {
      const submissionRef = doc(
        firestoreDB,
        `demos/${demo.id}/documents/${submission.id}`
      );
      batch.set(submissionRef, submission);
      index++;

      if (index >= 500) {
        await batch.commit();
        batch = writeBatch(firestoreDB);
        index = 0;
      }
    }

    await batch.commit();

    const ungradedSubmissions = submissions.filter(
      (s) =>
        [DocumentState.submitted, DocumentState.grading].includes(s.state) ==
          true || s.shouldCopyFullDocument == true
    );

    for (const submission of ungradedSubmissions) {
      const batch = writeBatch(firestoreDB);
      const submissionRef = doc(
        firestoreDB,
        `demos/${demo.id}/documents/${submission.id}`
      );
      batch.set(submissionRef, submission);

      const originRef = doc(firestoreDB, `documents/${submission.id}`);
      const destinationRef = doc(
        firestoreDB,
        `demos/${demo.id}/documents/${submission.id}`
      );

      await DemosService.fetchAndUpdateFullDocument(
        batch,
        originRef,
        destinationRef
      );

      await batch.commit();
    }
  }

  static async copyDemoToUser(userId: string, demoId: string) {
    const db = DBFactory.createDatabase();
    const firestoreDB = useFirestore();

    // Ok first we are going to need to delete all the exisitgn data for a user so we'll need to get all
    // of a users classrooms, assignemnts, students, and documents and delete them

    const modelsToDelete = [] as BaseModel[];

    const userClassroomEmailsData = await db.list(
      {
        collection: "classroomEmails",
        path: `classroomEmails`,
      },
      [
        {
          type: "where",
          field: "userId",
          operator: "==",
          value: userId,
        },
      ]
    );

    const userClassroomEmails = userClassroomEmailsData.map((data) => {
      return ClassroomEmail.fromMap(data);
    });

    modelsToDelete.push(...userClassroomEmails);

    const userClassroomData = await db.list(
      {
        collection: "classrooms",
        path: `classrooms`,
      },
      [
        {
          type: "where",
          field: "userId",
          operator: "==",
          value: userId,
        },
      ]
    );

    const userClassrooms = userClassroomData.map((data) => {
      return Classroom.fromMap(data);
    });

    modelsToDelete.push(...userClassrooms);

    const userAssignmentData = await db.list(
      {
        collection: "assignments",
        path: `assignments`,
      },
      [
        {
          type: "where",
          field: "userId",
          operator: "==",
          value: userId,
        },
      ]
    );

    const userAssignments = userAssignmentData.map((data) => {
      return Assignment.fromMap(data);
    });

    modelsToDelete.push(...userAssignments);

    const userStudentData = await db.list(
      {
        collection: "students",
        path: `students`,
      },
      [
        {
          type: "where",
          field: "userId",
          operator: "==",
          value: userId,
        },
      ]
    );

    const userStudents = userStudentData.map((data) => {
      return Student.fromMap(data);
    });

    modelsToDelete.push(...userStudents);

    await db.batchDelete(modelsToDelete);

    const userDocumentsRef = collection(firestoreDB, `documents`);
    const userDocumentsQuery = query(
      userDocumentsRef,
      where("userId", "==", userId)
    );
    const userDocumentsSnapshot = await getDocs(userDocumentsQuery);

    const deleteBatch = writeBatch(firestoreDB);

    for (const d of userDocumentsSnapshot.docs) {
      deleteBatch.delete(d.ref);
    }

    await deleteBatch.commit();

    ////////

    const demoPath = `demos/${demoId}`;

    const modelsToSave = [] as BaseModel[];

    const classroomEmailsData = await db.list({
      collection: `demos/${demoId}/classroomEmails`,
      path: `demos/${demoId}/classroomEmails`,
    });

    const classroomEmails = [] as ClassroomEmails;

    for (const data of classroomEmailsData) {
      try {
        const classroomEmailInfoData = await db.get({
          path: `demos/${demoId}/classroomEmails/${data.id}/info/${data.id}`,
          collection: `demos/${demoId}/classroomEmails/${data.id}/info`,
        });

        data["info"] = classroomEmailInfoData;
      } catch (error) {
        // empty
      }

      classroomEmails.push(ClassroomEmail.fromMap(data));
    }

    const formattedClassroomEmails = await DemosService.formatClassroomEmails(
      "",
      classroomEmails,
      userId
    );

    const formattedClassroomEmailsInfo = formattedClassroomEmails
      .filter((email) => email.info != undefined)
      .map((email) => {
        return email.info!;
      });

    modelsToSave.push(...formattedClassroomEmails);
    modelsToSave.push(...formattedClassroomEmailsInfo);

    const classroomData = await db.list({
      collection: `demos/${demoId}/classrooms`,
      path: `demos/${demoId}/classrooms`,
    });

    const classrooms = classroomData.map((data) => {
      return Classroom.fromMap(data);
    });

    const formattedClassrooms = await DemosService.formatClassrooms(
      "",
      classrooms,
      userId
    );
    modelsToSave.push(...formattedClassrooms);

    const assignmentData = await db.list({
      collection: `demos/${demoId}/assignments`,
      path: `demos/${demoId}/assignments`,
    });

    const assignments = assignmentData.map((data) => {
      return Assignment.fromMap(data);
    });

    const formattedAssignments = DemosService.formatAssignments(
      "",
      assignments,
      userId
    );
    modelsToSave.push(...formattedAssignments);

    const studentData = await db.list({
      collection: `demos/${demoId}/students`,
      path: `demos/${demoId}/students`,
    });

    const students = studentData.map((data) => {
      return Student.fromMap(data);
    });

    const formattedStudents = DemosService.formatStudents("", students, userId);
    modelsToSave.push(...formattedStudents);

    const studentLinkedAccounts = [] as StudentLinkedAccounts;

    for (const student of students) {
      const linkedAccountData = await db.list({
        collection: `demos/${demoId}/students/${student.id}/linkedAccounts`,
        path: `demos/${demoId}/students/${student.id}/linkedAccounts`,
      });

      const accounts = linkedAccountData.map((data) => {
        return StudentLinkedAccount.fromMap(data);
      });

      studentLinkedAccounts.push(...accounts);
    }

    const formattedStudentAccounts = DemosService.formatStudentLinkedAccounts(
      "",
      studentLinkedAccounts,
      userId
    );

    modelsToSave.push(...formattedStudentAccounts);

    await db.batchUpdate(modelsToSave);

    // // Copy transactions
    const studentCriteriaStatisticsData = await db.list({
      collection: `demos/${demoId}/assignmentStatistics/default/studentCriteria`,
      path: `demos/${demoId}/assignmentStatistics/default/studentCriteria`,
    });

    const studentCriteriaStatistics = studentCriteriaStatisticsData.map(
      (data) => {
        return StudentCriteriaStatistic.fromMap(data);
      }
    );

    await DemosService.saveStudentCriteriaTransactions(
      "",
      studentCriteriaStatistics,
      userId
    );

    // Get all submitted documents for the demo

    const exitingSubmissions = await DemosService.fetchAllUserDocuments(userId);
    let submissionDeleteBatch = writeBatch(firestoreDB);
    let deleteIndex = 0;

    for (const submission of exitingSubmissions) {
      submissionDeleteBatch.delete(
        doc(firestoreDB, `documents/${submission.id}`)
      );

      deleteIndex++;

      if (deleteIndex >= 500) {
        await submissionDeleteBatch.commit();
        submissionDeleteBatch = writeBatch(firestoreDB);
        deleteIndex = 0;
      }
    }

    await submissionDeleteBatch.commit();

    const submissionsRef = collection(firestoreDB, `demos/${demoId}/documents`);
    const submissionsSnapshot = await getDocs(submissionsRef);
    const submissions = submissionsSnapshot.docs.map((d) => {
      return {
        ...d.data(),
        id: d.id,
      } as SubmittedDocument;
    });

    const gradedSubmissions = submissions.filter(
      (s) => s.state == DocumentState.graded && s.shouldCopyFullDocument != true
    );

    let numGradedDocuments = 0;
    let gradedBatch = writeBatch(firestoreDB);
    let gradedIndex = 0;

    for (const submission of gradedSubmissions) {
      numGradedDocuments++;

      // Replace the document name and student name with the matching student name
      const student = formattedStudents.find(
        (s) => s.id == DemosService.formatId(submission.studentId!, userId)
      );

      this.copyDocumentToUser({
        submittedDocument: submission,
        studentId: student!.id!,
        studentName: student!.name!,
        userId,
        demoId,
        copyFullDocument: false,
        submissionType: submission.documentSubmissionType,
      });

      gradedIndex++;

      if (gradedIndex >= 500) {
        await gradedBatch.commit();
        gradedBatch = writeBatch(firestoreDB);
        gradedIndex = 0;
      }
    }

    await gradedBatch.commit();

    const ungradedSubmissions = submissions.filter(
      (s) =>
        [DocumentState.submitted, DocumentState.grading].includes(s.state) ==
          true || s.shouldCopyFullDocument == true
    );

    for (const submission of ungradedSubmissions) {
      // Replace the document name and student name with the matching student name
      const student = formattedStudents.find(
        (s) => s.id == DemosService.formatId(submission.studentId!, userId)
      );

      await this.copyDocumentToUser({
        submittedDocument: submission,
        userId,
        studentId: student!.id!,
        studentName: student!.name,
        demoId,
        copyFullDocument: true,
      });
    }

    // Last step is to update the number of graded documetns belonging to this student.
    const userRef = doc(firestoreDB, `users/${userId}`);
    await updateDoc(userRef, {
      numGradedDocuments: numGradedDocuments,
    });
  }

  static async copyDocumentToUser({
    submittedDocument,
    userId,
    studentId,
    studentName,
    demoId,
    copyFullDocument,
    initialBatch,
    submissionType,
  }: {
    submittedDocument: SubmittedDocument;
    userId: string;
    studentId: string;
    studentName: string;
    demoId?: string;
    copyFullDocument?: boolean;
    initialBatch?: WriteBatch;
    submissionType?: DocumentSubmissionType;
  }) {
    const firestoreDB = useFirestore();
    const batch = initialBatch ?? writeBatch(firestoreDB);

    const originalDocumentId = submittedDocument.id;

    const documentCopy = { ...submittedDocument } as SubmittedDocument;

    documentCopy.id = `${userId}-${studentId}-${useGenerateUID()}`;
    documentCopy.demoId = demoId;

    documentCopy.userId = userId;
    documentCopy.documentSubmissionType =
      submissionType ?? submittedDocument.documentSubmissionType;

    if (demoId != undefined && documentCopy.classroomId) {
      documentCopy.classroomId = DemosService.formatId(
        documentCopy.classroomId,
        userId
      );
    }

    if (demoId != undefined && documentCopy.assignmentId) {
      documentCopy.assignmentId = DemosService.formatId(
        documentCopy.assignmentId,
        userId
      );
    }

    documentCopy.studentId = studentId;

    documentCopy.name = `${studentName}_${submittedDocument.assignmentName}.docx`;
    documentCopy.studentName = studentName;
    documentCopy.enteredName = studentName;

    if (
      [
        DocumentSubmissionType.keyWordOutline,
        DocumentSubmissionType.roughDraft,
      ].includes(
        documentCopy.documentSubmissionType ?? DocumentSubmissionType.finalDraft
      )
    ) {
      // Genetate true 80 of the time:
      const isAccepted = Math.random() > 0.05;
      documentCopy.documentGradedState = isAccepted
        ? DocumentGradedState.accepted
        : DocumentGradedState.incomplete;
    } else {
      documentCopy.maxPoints = 100;
      documentCopy.earnedPoints = Math.floor(Math.random() * 51) + 50;
    }

    const submissionRef = doc(firestoreDB, `/documents/${documentCopy.id}`);
    batch.set(submissionRef, {
      ...documentCopy,
      originalDocumentId: originalDocumentId,
    });

    // // OK here is whervare i need to update the full paths now. The interesting this is that I need to
    // // Figure out the path to get the document from IEW am I getting th
    // // Ok so I think I need a documents origin ref and destination ref

    if (
      copyFullDocument == true ||
      documentCopy.shouldCopyFullDocument == true
    ) {
      const documentsOriginRef = doc(
        firestoreDB,
        demoId != undefined
          ? `/demos/${demoId}/documents/${submittedDocument.id}`
          : `/documents/${submittedDocument.id}`
      );
      const documentDestinationRef = doc(
        firestoreDB,
        `/documents/${submissionRef.id}`
      );

      await DemosService.fetchAndUpdateFullDocument(
        batch,
        documentsOriginRef,
        documentDestinationRef
      );
    }

    if (initialBatch == undefined) {
      await batch.commit();
    }
  }

  static async copyClassroomsResources({
    demoId,
    classrooms,
  }: {
    demoId: string;
    classrooms: Classrooms;
  }) {
    const storage = useFirebaseStorage();

    for (const classroom of classrooms) {
      for (const resource of classroom.resources) {
        if (resource.type != ResourceType.file) continue;

        const filePath = (resource as FileResource).mediaObject.fullPath;
        const fileRef = FileRef(storage, filePath);
        // Copy this file to a new file ref path
        const newFilePath = `demo-resources/${demoId}/${Date.now()}_${
          fileRef.name
        }`;

        await $fetch(`/api/admin/files/copy`, {
          method: "POST",
          headers: await useApiHeaders(),
          body: {
            originalPath: filePath,
            newPath: newFilePath,
          },
        });

        const newFileRef = FileRef(storage, newFilePath);

        const downloadUrl = await getDownloadURL(newFileRef);

        (resource as FileResource).mediaObject.fullPath = newFilePath;
        (resource as FileResource).mediaObject.bucket = newFileRef.bucket;
        (resource as FileResource).mediaObject.mediaHref = downloadUrl;
      }
    }
  }

  static async formatClassroomEmails(
    destinationPath: string,
    classroomEmails: ClassroomEmails,
    userId?: string
  ) {
    return classroomEmails.map((classroomEmail) => {
      classroomEmail.id = DemosService.formatId(classroomEmail.id!, userId);

      if (classroomEmail.classroomId) {
        classroomEmail.classroomId = DemosService.formatId(
          classroomEmail.classroomId,
          userId
        );
      }

      classroomEmail.pathPrefixOverride = destinationPath;

      if (userId) {
        classroomEmail.userId = userId;
      }

      if (classroomEmail.info) {
        classroomEmail.info.id = classroomEmail.id;

        if (userId) {
          classroomEmail.info.userId = userId;
        }

        classroomEmail.info.pathPrefixOverride = destinationPath;
      }

      return classroomEmail;
    });
  }

  static async formatClassrooms(
    destinationPath: string,
    classrooms: Classrooms,
    userId?: string
  ) {
    return classrooms.map((classroom) => {
      classroom.id = DemosService.formatId(classroom.id!, userId);
      classroom.externalId = DemosService.formatId(classroom.id!, userId);
      classroom.externalProvider = "iew";

      for (const group of classroom.classroomAssignmentGroups ?? []) {
        group.assignmentIds = group.assignmentIds.map((assignmentId) => {
          return DemosService.formatId(assignmentId, userId);
        });
      }

      if (userId) {
        classroom.userId = userId;
      }

      classroom.pathPrefixOverride = destinationPath;

      return classroom;
    });
  }

  static formatAssignments(
    destinationPath: string,
    assignments: Assignments,
    userId?: string
  ) {
    return assignments.map((assignment, index) => {
      assignment.id = DemosService.formatId(assignment.id!, userId);
      assignment.externalId = assignment.id;
      assignment.externalProvider = "iew";
      assignment.classroomId = DemosService.formatId(
        assignment.classroomId,
        userId
      );

      if (userId) {
        assignment.userId = userId;
      }

      assignment.modelDatabaseConfig = {
        collection: `${destinationPath}/assignments`,
        path: `${destinationPath}/assignments/${assignment.id}`,
      };

      return assignment;
    });
  }

  static formatStudentLinkedAccounts(
    destinationPath: string,
    studentLinkedAccounts: StudentLinkedAccounts,
    userId?: string
  ) {
    return studentLinkedAccounts.map((linkedAccount) => {
      linkedAccount.studentId = DemosService.formatId(
        linkedAccount.studentId,
        userId
      );

      linkedAccount.classroomId = DemosService.formatId(
        linkedAccount.classroomId,
        userId
      );

      linkedAccount.id = DemosService.formatId(linkedAccount.id!, userId);

      if (userId) {
        linkedAccount.userId = userId;
      }

      linkedAccount.pathPrefixOverride = destinationPath;

      return linkedAccount;
    });
  }

  static formatStudents(
    destinationPath: string,
    students: Students,
    userId?: string
  ) {
    return students.map((student) => {
      const studentCopy = Student.fromMap(student.toMap());
      studentCopy.id = DemosService.formatId(studentCopy.id!, userId);
      studentCopy.externalId = studentCopy.id!;
      studentCopy.externalProvider = "iew";
      studentCopy.classroomIds = studentCopy.classroomIds.map((classroomId) =>
        DemosService.formatId(classroomId, userId)
      );

      if (userId) {
        studentCopy.userId = userId;
      }

      studentCopy.modelDatabaseConfig = {
        collection: `/students`,
        path: `/students/${studentCopy.id}`,
      };

      studentCopy.pathPrefixOverride = destinationPath;

      // Generate a random student name:
      // student.name = HumanNames.allRandom();

      return studentCopy;
    });
  }

  static async saveStudentCriteriaTransactions(
    destinationPath: string,
    studentCriteriaStatistics: StudentCriteriaStatistics,
    userId?: string
  ) {
    let index = 0;

    const db = useFirestore();

    let batch = writeBatch(db);

    for (const statistic of studentCriteriaStatistics) {
      if (index >= 100) {
        await batch.commit();
        batch = writeBatch(db);
      }

      const statisticCopy = StudentCriteriaStatistic.fromMap(statistic.toMap());

      statisticCopy.classroomId = DemosService.formatId(
        statisticCopy.classroomId,
        userId
      );
      statisticCopy.teacherId = userId ?? statisticCopy.teacherId;

      statisticCopy.studentId = DemosService.formatId(
        statisticCopy.studentId,
        userId
      );

      const assignmentIds = Object.keys(statisticCopy.mapAssignmentIdScore);

      const newMap = {} as any;

      for (const assignmentId of assignmentIds) {
        const newAssignmentId = DemosService.formatId(
          assignmentId,
          userId
        ) as string;

        newMap[newAssignmentId] =
          statisticCopy.mapAssignmentIdScore[assignmentId];
      }

      statisticCopy.mapAssignmentIdScore = newMap;

      const statisticRef = doc(
        db,
        `${destinationPath}${statisticCopy.databaseConfig.path}`
      );

      batch.set(statisticRef, statisticCopy.toMap());

      index++;
    }

    await batch.commit();
  }

  static async saveDocumentAsSample(documentId: string) {
    const firestoreDB = useFirestore();
    const originRef = doc(firestoreDB, `documents/${documentId}`);
    const destinationref = doc(firestoreDB, `sampleDocuments/${documentId}`);

    const documentSnapshot = await getDoc(originRef);
    const document = documentSnapshot.data() as SubmittedDocument;

    const batch = writeBatch(firestoreDB);

    batch.set(destinationref, document);

    await this.fetchAndUpdateFullDocument(batch, originRef, destinationref);

    await batch.commit();
  }

  static async copySampleToUser(documentId: string, userId: string) {
    const firestoreDB = useFirestore();
    const originRef = doc(firestoreDB, `sampleDocuments/${documentId}`);
    const destinationRef = doc(
      firestoreDB,
      `documents/${userId}-${documentId}-${useGenerateUID()}`
    );

    const timestamp = new Date().getTime();
    const fireTimestamp = Timestamp.now();

    const sampleDocumentSnapshot = await getDoc(originRef);
    const sampleDocument = sampleDocumentSnapshot.data() as SubmittedDocument;
    sampleDocument.userId = userId;
    sampleDocument.state = DocumentState.converting;
    sampleDocument.lastUpdatedTimestamp = timestamp;
    sampleDocument.createOnTimestamp = timestamp;
    sampleDocument.submittedAtTimestamp = fireTimestamp;

    await setDoc(destinationRef, sampleDocument);
    const batch = writeBatch(firestoreDB);

    await this.fetchAndUpdateFullDocument(batch, originRef, destinationRef);

    await batch.commit();

    await updateDoc(destinationRef, {
      state: DocumentState.submitted,
      createdOnTimestamp: timestamp,
      lastUpdatedTimestamp: timestamp,
      submittedAtTimestamp: fireTimestamp,
    });
  }

  static async fetchAndUpdateFullDocument(
    batch: WriteBatch,
    originRef: DocumentReference<DocumentData>,
    destinationRef: DocumentReference<DocumentData>
  ) {
    const revisionsRef = collection(originRef, `/revisions`);
    const revisionSnapshot = await getDocs(revisionsRef);
    const revisions = revisionSnapshot.docs.map((d) => {
      return {
        ...d.data(),
        id: d.id,
      } as Revision;
    });

    if (revisions.length == 0) return;

    const revision = revisions[0];
    const revisionRef = revisionSnapshot.docs[0].ref;

    const revisionDemoRef = doc(destinationRef, `/revisions/${revision.id}`);
    batch.set(revisionDemoRef, revision);

    // Do this for paragraphs
    await DemosService.fetchAndSetParagraphs(
      batch,
      revisionRef,
      revisionDemoRef
    );

    // Evaluative Paragraphs
    await DemosService.fetchAndSetEvaluativeParagraphs(
      batch,
      revisionRef,
      revisionDemoRef
    );

    // Evaluation results
    await DemosService.fetchAndSetEvalautionResults(
      batch,
      revisionRef,
      revisionDemoRef
    );

    // Annotatinos
    await DemosService.fetchAndSetAnnotations(
      batch,
      revisionRef,
      revisionDemoRef
    );

    // checklists
    await DemosService.fetchAndSetChecklists(
      batch,
      revisionRef,
      revisionDemoRef
    );
  }

  static async fetchAndSetParagraphs(
    batch: WriteBatch,
    originRef: DocumentReference<DocumentData>,
    destinationRef: DocumentReference<DocumentData>
  ) {
    const firestoreDB = useFirestore();

    const paragraphsRef = collection(originRef, `/paragraphs`);
    const paragraphsSnapshot = await getDocs(paragraphsRef);

    for (const snapshot of paragraphsSnapshot.docs) {
      const paragraph = snapshot.data() as Paragraph;

      const paragraphDemoRef = doc(destinationRef, `paragraphs/${snapshot.id}`);
      batch.set(paragraphDemoRef, paragraph);
    }
  }

  static async fetchAndSetEvaluativeParagraphs(
    batch: WriteBatch,
    originRef: DocumentReference<DocumentData>,
    destinationRef: DocumentReference<DocumentData>
  ) {
    const firestoreDB = useFirestore();

    const paragraphsRef = collection(originRef, `/evaluativeParagraphs`);
    const paragraphsSnapshot = await getDocs(paragraphsRef);

    for (const snapshot of paragraphsSnapshot.docs) {
      const paragraph = snapshot.data() as Paragraph;

      const paragraphDemoRef = doc(
        destinationRef,
        `evaluativeParagraphs/${snapshot.id}`
      );
      batch.set(paragraphDemoRef, paragraph);
    }
  }

  static async fetchAndSetEvalautionResults(
    batch: WriteBatch,
    originRef: DocumentReference<DocumentData>,
    destinationRef: DocumentReference<DocumentData>
  ) {
    const firestoreDB = useFirestore();

    const resultsRef = collection(originRef, `/evaluationResults`);
    const resultsSnapshot = await getDocs(resultsRef);

    for (const snapshot of resultsSnapshot.docs) {
      const result = snapshot.data() as Paragraph;

      const resultDemoRef = doc(
        destinationRef,
        `evaluationResults/${snapshot.id}`
      );
      batch.set(resultDemoRef, result);
    }
  }

  static async fetchAndSetAnnotations(
    batch: WriteBatch,
    originRef: DocumentReference<DocumentData>,
    destinationRef: DocumentReference<DocumentData>
  ) {
    const firestoreDB = useFirestore();

    const annotationsRef = collection(originRef, `/annotations`);
    const annotationsSnapshot = await getDocs(annotationsRef);

    for (const snapshot of annotationsSnapshot.docs) {
      const annotation = snapshot.data() as Paragraph;

      const annotationDemoRef = doc(
        destinationRef,
        `annotations/${snapshot.id}`
      );
      batch.set(annotationDemoRef, annotation);
    }
  }

  static async fetchAndSetChecklists(
    batch: WriteBatch,
    originRef: DocumentReference<DocumentData>,
    destinationRef: DocumentReference<DocumentData>
  ) {
    const firestoreDB = useFirestore();

    const checklistsRef = collection(originRef, `/checklists`);
    const checklistsSnapshot = await getDocs(checklistsRef);

    for (const snapshot of checklistsSnapshot.docs) {
      const checklist = snapshot.data() as Paragraph;

      const checklistDemoRef = doc(destinationRef, `checklists/${snapshot.id}`);
      batch.set(checklistDemoRef, checklist);

      const sectionsReference = collection(
        checklistsRef,
        `${snapshot.id}/sections`
      );

      await DemosService.fetchAndSetChecklistSectionsRecursive(
        batch,
        checklistDemoRef,
        sectionsReference
      );
    }
  }

  static async fetchAndSetChecklistSectionsRecursive(
    batch: WriteBatch,
    demoChecklistRef: DocumentReference<DocumentData>,
    checklistSectionRef: CollectionReference<DocumentData>
  ) {
    const sectionSnapshot = await getDocs(checklistSectionRef);

    for (const snapshot of sectionSnapshot.docs) {
      const section = snapshot.data() as Paragraph;

      const sectionDemoRef = doc(demoChecklistRef, `sections/${snapshot.id}`);
      batch.set(sectionDemoRef, section);

      const subSectionsReference = collection(
        checklistSectionRef,
        `${snapshot.id}/sections`
      );

      await DemosService.fetchAndSetChecklistSectionsRecursive(
        batch,
        sectionDemoRef,
        subSectionsReference
      );
    }
  }

  static formatId(id: string, userId?: string) {
    try {
      let newId = id.replace("sakai", "iew");

      if (userId) {
        const idParts = newId.split("-");
        idParts[1] = userId;
        newId = idParts.join("-");
      }

      return newId;
    } catch (error) {
      return id;
    }
  }

  static async resetDemo(userId: string, documents: SubmittedDocuments) {
    // so I think I want to do this. Loops over each document. If there's a demo id, get the demo document verion otherwise delete it.
    // oh interesting. It's only grabbing ungraded documents.'

    const firestoreDB = useFirestore();
    const demoId = documents[0].demoId;

    const demoDocumentsRef = collection(
      firestoreDB,
      `/demos/${demoId}/documents`
    );
    const demoDocumentsQuery = query(
      demoDocumentsRef,
      where("state", "in", [DocumentState.grading, DocumentState.submitted])
    );
    const demoDocumentsSnapshot = await getDocs(demoDocumentsQuery);
    const demoDocuments = demoDocumentsSnapshot.docs.map((d) => {
      return {
        ...d.data(),
        id: d.id,
      } as SubmittedDocument;
    });

    const demoDocumentIds = demoDocuments.map((d) => d.id);

    const relevantDocuments = documents.filter((d) => {
      if (demoDocumentIds.includes(d.originalDocumentId) !== true) return false;

      const demoDocument = demoDocuments.find(
        (dd) => dd.id == d.originalDocumentId
      );

      if (demoDocument == undefined) return false;

      if (demoDocument.state != d.state) return true;

      return false;
    });

    for (const document of relevantDocuments) {
      const documentRef = doc(firestoreDB, `documents/${document.id}`);

      const batch = writeBatch(firestoreDB);

      const demoDocumentRef = doc(
        firestoreDB,
        `demos/${document.demoId}/documents/${document.originalDocumentId}`
      );

      const demoDocument = demoDocuments.find(
        (dd) => dd.id == document.originalDocumentId
      );

      // Delete the original document's paragraphs, checklist, etc and then call the fetch and copy full document function

      const revisionsRef = collection(
        firestoreDB,
        `${documentRef.path}/revisions`
      );
      const revisionsSnapshot = await getDocs(revisionsRef);

      for (const snapshot of revisionsSnapshot.docs) {
        batch.delete(snapshot.ref);
      }

      if (revisionsSnapshot.docs.length == 0) {
        continue;
      }

      const baseRevisionRef = revisionsSnapshot.docs[0].ref;

      const paragraphsRef = collection(
        firestoreDB,
        `/${baseRevisionRef.path}/paragraphs`
      );
      const paragraphsSnapshot = await getDocs(paragraphsRef);

      for (const snapshot of paragraphsSnapshot.docs) {
        batch.delete(snapshot.ref);
      }

      const evaluativeParagraphsRef = collection(
        firestoreDB,
        `${baseRevisionRef.path}/evaluativeParagraphs`
      );

      const evaluativeParagraphsSnapshot = await getDocs(
        evaluativeParagraphsRef
      );

      for (const snapshot of evaluativeParagraphsSnapshot.docs) {
        batch.delete(snapshot.ref);
      }

      const evaluationResultsRef = collection(
        firestoreDB,
        `${baseRevisionRef.path}/evaluationResults`
      );
      const evaluationResultsSnapshot = await getDocs(evaluationResultsRef);

      for (const snapshot of evaluationResultsSnapshot.docs) {
        batch.delete(snapshot.ref);
      }

      const annotationsRef = collection(
        firestoreDB,
        `${baseRevisionRef.path}/annotations`
      );
      const annotationsSnapshot = await getDocs(annotationsRef);

      for (const snapshot of annotationsSnapshot.docs) {
        batch.delete(snapshot.ref);
      }

      const checklistsRef = collection(
        firestoreDB,
        `${baseRevisionRef.path}/checklists`
      );
      const checklistsSnapshot = await getDocs(checklistsRef);

      for (const snapshot of checklistsSnapshot.docs) {
        batch.delete(snapshot.ref);
      }

      await batch.commit();

      const newBatch = writeBatch(firestoreDB);

      const destinationDocRef = doc(firestoreDB, documentRef.path);

      document.lastUpdatedTimestamp =
        demoDocument?.lastUpdatedTimestamp ?? document.lastUpdatedTimestamp;
      document.state = demoDocument?.state ?? document.state;

      newBatch.set(destinationDocRef, document);

      await DemosService.fetchAndUpdateFullDocument(
        newBatch,
        demoDocumentRef,
        destinationDocRef
      );

      await newBatch.commit();
    }

    const documentResetBatch = writeBatch(firestoreDB);

    for (const document of documents) {
      if (
        [DocumentState.submitted, DocumentState.grading].includes(
          document.state
        ) != true
      )
        continue;

      // If we are expected to copy the full document then we want to also make sure the submitted at timestamp is a random time between the closest monday and the current day
      const now = new Date();
      const day = now.getDay();
      const diff = now.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is Sunday
      const closestMonday = new Date(now);
      closestMonday.setDate(diff);
      const randomTime =
        Math.random() * (now.getTime() - closestMonday.getTime()) +
        closestMonday.getTime();
      const fireTimestamp = Timestamp.fromMillis(randomTime);

      document.submittedAtTimestamp = fireTimestamp;

      const documentRef = doc(firestoreDB, `/documents/${document.id}`);

      documentResetBatch.update(documentRef, document);
    }

    await documentResetBatch.commit();

    const classrooms = await ClassroomsService.listUserClassrooms(userId);

    const assignmentIndex = 9;

    for (const classroom of classrooms) {
      const classroomAssignments = await AssignmentsService.listAssignments(
        classroom.id!,
        userId
      );

      const today = new Date();
      const closestMonday = DemosService.getClosestWeekday(today, 1); // 1 is Monday
      const closestWednesday = DemosService.getClosestWeekday(closestMonday, 3); // 3 is Wednesday
      const closestFriday = DemosService.getClosestWeekday(closestMonday, 5); // 5 is Friday

      let openTimestamp = DemosService.subtractWeeks(
        closestMonday.getTime(),
        assignmentIndex
      );
      let dueTimestamp = DemosService.subtractWeeks(
        closestWednesday.getTime(),
        assignmentIndex
      );
      let closeTimestamp = DemosService.subtractWeeks(
        closestFriday.getTime(),
        assignmentIndex
      );

      const sortedAssignments = useSortAssignments(classroomAssignments);

      for (const assignment of sortedAssignments) {
        assignment.dueTimestamp = dueTimestamp;
        assignment.openTimestamp = openTimestamp;
        assignment.closeTimestamp = closeTimestamp;

        dueTimestamp = DemosService.addWeeks(dueTimestamp, 1);
        openTimestamp = DemosService.addWeeks(openTimestamp, 1);
        closeTimestamp = DemosService.addWeeks(closeTimestamp, 1);
      }

      const db = DBFactory.createDatabase();
      await db.batchUpdate(sortedAssignments);
    }
  }

  static getClosestWeekday(date: Date, targetDay: number) {
    const dayOfWeek = date.getDay();
    const diff = (targetDay - dayOfWeek + 7) % 7;
    const closestDay = new Date(date);
    closestDay.setDate(date.getDate() + diff);
    return closestDay;
  }

  static subtractWeeks(date: number, weeks: number) {
    const result = new Date(date);
    result.setDate(result.getDate() - weeks * 7);
    return result.getTime();
  }

  static addWeeks(date: number, weeks: number) {
    const result = new Date(date);
    result.setDate(result.getDate() + weeks * 7);
    return result.getTime();
  }

  static async fetchAllUserDocuments(userId: string) {
    const db = useFirestore();
    const documentsRef = collection(db, `documents`);
    const documentsQuery = query(documentsRef, where("userId", "==", userId));
    const documentsSnapshot = await getDocs(documentsQuery);

    return documentsSnapshot.docs
      .map((d) => {
        return {
          ...d.data(),
          id: d.id,
        } as SubmittedDocument;
      })
      .filter((d) => d.state != DocumentState.archived);
  }

  static async fetchAllStudentCriteriaTransactions(userId: string) {
    const db = useFirestore();
    const criteriaRef = collection(
      db,
      `assignmentStatistics/default/studentCriteria`
    );
    const criteriaQuery = query(criteriaRef, where("teacherId", "==", userId));
    const criteriaSnapshot = await getDocs(criteriaQuery);

    return criteriaSnapshot.docs.map((d) => {
      return StudentCriteriaStatistic.fromMap(d.data());
    });
  }
}
